import React, { lazy, Suspense } from "react";
import Index from './../components/front/Index'
import About from './../components/front/About';
import Blog from './../components/front/Blog';
import CostComparison from './../components/front/CostComparison';
import Brewers from './../components/front/Brewers';
import Buyers from './../components/front/Buyers';
import Resources from './../components/front/Resource';
import Terms from './../components/front/Termsofuse';
import Privacy from './../components/front/PrivacyPolicy';
import PressRelease from './../components/front/PressRelease';
import CraftBrewNews from './../components/front/CraftBrewNews.js';
import BevPortDistribution from './../components/front/BevPortDistribution.js';

import Contactus from './../components/front/Contact';
import Jointherevolution from './../components/front/JoinTheRevolution';
import Login from './../components/front/Login';
import ForgotLogin from './../components/front/ForgotPass';
import ResetPassword from './../components/front/ResetPassword';
import RegistrationPage from './../components/front/RegistrationPage';
import BuyerRegistrationPage from './../components/front/RegistrationPageBuyer';
import BrewersPaymentForms from './../components/front/BrewerPaymentForms';
import OrderDetail from "./../components/front/OrderDetials";
import BlankPage from './../components/front/BlankPage';
import SalesServicesContact from '../components/front/SalesServicesContact';
import SalesServicesLearnMore from '../components/front/SalesServicesLearnMore';

const route = [
  { path: '/', Component: Index },
  { path: '/login', Component: Login },
  { path: '/forgot', Component: ForgotLogin },
  { path: '/reset-password/:token', Component: ResetPassword },
  { path: '/about', Component: About },
  { path: '/blog', Component: Blog },
  { path: '/CostComparison', Component: CostComparison },
  { path: '/brewers', Component: Brewers },
  { path: '/buyers', Component: Buyers },
  { path: '/brewers/:tabID/:userID', Component: Brewers },
  { path: '/buyers/:tabID/:userID', Component: Buyers },
  { path: '/resources', Component: Resources },
  { path: '/termsofuse', Component: Terms },
  { path: '/privacy', Component: Privacy },
  { path: '/press-release', Component: PressRelease },
  { path: '/CraftBrewNews', Component: CraftBrewNews },
  { path: '/BevPortDistribution', Component: BevPortDistribution },
  { path: '/contact', Component: Contactus },
  { path: '/Jointherevolution', Component: Jointherevolution },
  { path: '/brewer-registration', Component: RegistrationPage },
  { path: '/buyer-registration', Component: BuyerRegistrationPage },
  { path: '/brewers-payments', Component: BrewersPaymentForms },
  { path: '/view-invoice/:orderID/:userID/:type', Component: OrderDetail },
  { path: '/view-invoice/:orderID/:userID', Component: OrderDetail },
  { path: '/Invoice/:orderID/:type', Component: BlankPage },
  { path: '/SalesServicesContact', Component: SalesServicesContact },
  { path: '/SalesServicesLearnMore', Component: SalesServicesLearnMore },


]
export default route;