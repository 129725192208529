import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Loader from "./../front/Loader";
import Header from "./Header";
import Footer from "./Footer";
import Logo from './../../assets/images/logo.png'
import BevPortLogo from './../../assets/images/bevport-blogo.png'
import NewPlanetLogo from './../../assets/images/newPlanet.jpg'
import NewPlanetCan from './../../assets/images/newplanetcan.jpg'
import SalesSheet from './../../assets/images/sales-sheet.jpg'
import DownloadPDF from './../../assets/images/downloadpdf.png'
import PdfImage from './../../assets/images/filelogo.png'
import ManonMountains from './../../assets/images/manonmountains.jpg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import { Slide, ToastContainer, toast } from 'react-toastify';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'react-toastify/dist/ReactToastify.css';
import { propTypes } from 'react-bootstrap/esm/Image';


const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
toast.configure()

export default function ProductDetailNew() {
	document.title = "BevPort - Product Detail"
	const { productID } = useParams();
	let navigate = useNavigate()
	const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false)
	const [productData, setProductData] = useState("")
	const [files, setFiles] = useState([])
	const [brandFiles, setBrandFiles] = useState([])
	const [similarProduct, setSimilarProduct] = useState([])
	const [submitLoader, setSubmitLoader] = useState(true)
	const [images, setImages] = useState([])
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		if (productID) {

			getProductDetail(productID)
		} else {
			navigate("/brewer/product-list")
		}
	}, [productID])
	const getProductDetail = async (productID) => {
		await axios.post(API_URL + "ProductDetailsByID?code=" + API_KEY, { ID: productID }, config).then((res) => {
			console.log("productData", res);
			if (res.data.responseCode == "200" && res.data.responseData.length) {
				setProductData(res.data.responseData[0])

				let filesData = res.data.responseData[0]?.blobFiles;
				let filteredData = filesData.filter((file) => {
					return file?.filetype === "ProductImage"
				})
				setFiles(filteredData)
				setBrandFiles(res.data.responseData[0]?.brandFiles)
				setSimilarProduct(res.data.responseData[0]?.similarProducts)

			}
			setSubmitLoader(false)
		}).catch(function (error) {
			setSubmitLoader(false)
		})
	}


	const handleAlertBox = () => {
		toast.error("You don't have selling price. Please enter selling price before activating the product.")
	}
	const handleAlertCompBox = () => {
		toast.error("You can't activate your product because product compliance is not approved by Admin.")
	}
	const handleActiveInactiveStatus = async (productID, status) => {
		let formData = {
			"ID": productID,
			"Status": (status) ? 0 : 1
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeProductStatus?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode === "200") {
				toast.success(res.data.responseMessage)
				getProductDetail(productID)
			} else {
				toast.error(res.data.responseMessage)
			}
			setLoader(false);
		}).catch(function (error) {
			setLoader(false);
		})
	}

	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
				{submitLoader ? <Loader /> : ""}
				{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Product Details</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content product-detailNewbg">
								<div className="Content-body">
									<div className="row mbs-50">
										{
											(brandFiles.length) ? brandFiles.map((_v, _i) => {

												if (_v.filetype == 'BrandLogo') {
													return (
														<div className="col-md-2">
															<div className="mainProductLogo">
																<img className="img-responsive" src={_v.filepath} />
															</div>
														</div>
													)
												}

											}) : <div className="col-md-2">
												<div className="mainProductLogo">

												</div>
											</div>
										}

										<div className="col-md-10">
											<div className="productTopDetilas">
												<h2 className="activeProductname">{productData?.productName}</h2>
												<h3 className="ProdcutTopBrandName">{productData?.brandName}</h3>
												<div className="skuDetails">
													<span>SKU: 123456789</span><span>Product UPC: {productData?.productUPC}</span><span>SCC: 000888</span>
												</div>
												<div className="canbeerSize">
													<span>12 oz</span><span>4-pack</span><span>Case of 6</span>
												</div>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											<h3 className="aboutHeading">ABOUT THIS ITEM: <span>{productData?.productName}</span></h3>
										</div>
									</div>
									<div className="row">


										<div className="col-md-4">
											<div className="productQtyImg">

												<div id="carousel" className="carousel slide" data-interval="3000" data-ride="carousel" data-interval="false">
													<div className="carousel-inner increaseHeight">
														<Carousel showIndicators={false} swipeable={true} showStatus={false} autoFocus={true} showArrows={false}
														>
															{
																files.map((_v, _i) => {
																	return (
																		<div>
																			<img src={_v?.filepath} />

																		</div>
																	)
																})
															}


														</Carousel>
													</div>
												</div>






											</div>
										</div>
										<div className="col-md-8">
											<div className="productQtyCheck">
												<h2 className="activeProductPrice">${parseFloat(productData?.casePrice).toFixed(2)}</h2>
												<h4>Case of 6, 4-packs</h4>
												<div className="qtyIncreses">
													<span className="qtyText">QTY 1</span>
												</div>
												<h2 className="activeProductPrice">${productData?.packPrice}</h2>
												<h4>Each 4-pack of 12 oz. cans</h4>
											</div>
										</div>
									</div>

									<div className="row mbs-50">
										<div className="col-md-12">
											<div className="cutosyText">
												<p><strong>{(productData?.keyWords) ? "Keywords : " : ""}</strong>{(productData?.keyWords) ? productData?.keyWords.replace(",", " • ") : ""}</p>
												<p><strong>{(productData?.description) ? "Product Description : " : ""} </strong>{productData?.description}
												</p>
												<p><strong>{(productData?.onTheNose) ? "On the Nose : " : ""} </strong>{productData?.onTheNose}</p>
												<p><strong>{(productData?.tastingNotes) ? "Tasting Notes : " : ""} </strong>{productData?.tastingNotes}</p>
												<p><strong>{(productData?.brewersNotes) ? "Brewers Notes : " : ""} </strong>{productData?.brewersNotes}</p>
												<p><strong>{(productData?.awardsRecog) ? "Awards & Recognition : " : ""} </strong>{productData?.awardsRecog}</p>
												<p>{productData?.alcoholPercentage}% ABV • 33 IBU</p>
												<p>{productData?.calories} CAL • {productData?.sugar}G SUGAR • {productData?.carbs}G CARBS</p>
											</div>
											<div className="sizeBifercation">
												<p><span>TYPE</span>: Read-to-drink</p>
												<p><span>COUNTRY</span>: United States</p>
												<p><span>STATES</span>: {productData?.stateName}</p>
											</div>

										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											<h3 className="aboutHeading">COLLATERAL</h3>
										</div>
									</div>
									<div className="row colds">
										{
											brandFiles.map((_v, _i) => {

												if (_v.filetype == "collatral") {
													let filename = _v?.filename

													function getFileExtension(filename) {
														return filename.split('.').pop();
													}
													
													return (
														<div className="col-md-4">
															<div className="collatrelDocs">

																{(getFileExtension(filename) == 'jpg' || getFileExtension(filename) == 'jpeg' || getFileExtension(filename) == 'png' || getFileExtension(filename) == 'svg') ? <img src={_v.filepath} /> : <img src={PdfImage} />}
																<Link className="downloadbtns" to="#"><img src={DownloadPDF} /></Link>
															</div>
														</div>
													)
													


												}
											})
										}


									</div>

									<div className="row simmilarmargins">
										<div className="col-md-12">
											<h3 className="aboutHeading">OTHER PRODUCTS FROM THIS MAKER</h3>
										</div>
									</div>
									<div className="row">
										{
											similarProduct.map((_v, _i) => {


												return (
													<div className="similarProducts">
														<div className="col-md-3 mb-20">
															<Card sx={{ maxWidth: 345 }} className="product-list-component">
																<Link to={"/admin/product-detail/" + _v?.id}>
																	<CardMedia
																		component="img"
																		className="product-image"
																		alt={_v?.productName}
																		height="210"
																		image={(_v?.filePath) ? _v?.filePath : Logo}
																	/>
																</Link>
																<CardContent>
																	<Typography gutterBottom variant="h4" component="div">
																		{_v?.productName}
																	</Typography>
																	<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																		{_v?.brandName}
																	</Typography>
																	<Typography variant="body2" color="text.secondary" sx={{ fontSize: '11px' }}>
																		{_v?.beerTypeVal}
																	</Typography>
																</CardContent>
																<Divider />
																<CardContent className="ProductListCardContent">
																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Can Size
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.canSizeVal}
																			</Typography>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Case Size
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.caseSizeVal}
																			</Typography>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Size
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.packageSizeVal}
																			</Typography>
																		</div>
																	</div>
																	{
																	_v?.bevPortListingCasePrice ?
																		<div className="row mutliplePrices">
																			<div className="col-md-6">
																				<Typography gutterBottom variant="h6" component="div">
																					Case Price
																				</Typography>
																			</div>
																			<div className="col-md-6">
																				<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																					${parseFloat(_v?.bevPortListingCasePrice).toFixed(2)}
																				</Typography>

																			</div>
																		</div> : ""}
																{
																	_v?.bevPortListingPackPrice ? <div className="row mutliplePrices">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Pack Price
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				${parseFloat(_v?.bevPortListingPackPrice).toFixed(2)}
																			</Typography>

																		</div>
																	</div> : ""}
																	{
																		_v?.bevPortListingUnitPrice ? <div className="row mutliplePrices">
																			<div className="col-md-6">
																				<Typography gutterBottom variant="h6" component="div">
																					Unit Price
																				</Typography>
																			</div>
																			<div className="col-md-6">
																				<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																					${parseFloat(_v?.bevPortListingUnitPrice).toFixed(2)}
																				</Typography>

																			</div>
																		</div> : ""
																	}

																	<div className="row">
																		<div className="col-md-6">
																			<Typography gutterBottom variant="h6" component="div">
																				Status
																			</Typography>
																		</div>
																		<div className="col-md-6">
																			<Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
																				{_v?.status == "1" ? "Active" : "In Active"}
																			</Typography>
																		</div>
																	</div>
																</CardContent>
																<CardActions className="cardactionBtns buttonWidth">
																	<Link className="btn btn-secondary" to="#" disabled={(_v?.complianceSubmitted == 1 || _v?.complianceSubmitted == 0)?true:false} onClick={() => (_v?.bevPortListingUnitPrice != "0" || _v?.bevPortListingPackPrice != "0" || _v?.bevPortListingCasePrice != "0")?((_v?.complianceSubmitted == 3)?handleActiveInactiveStatus(_v?.id, _v?.status):handleAlertCompBox()):handleAlertBox()}>{(_v?.status) ? "Deactivate" : "Activate"}</Link>
																</CardActions>
															</Card>
														</div>


													</div>
												)
											})
										}

									</div>

									<div className="row mbs-50">
										<div className="col-md-12">
											<div className="aboutsperator">
												<h3 className="aboutHeading">ABOUT THIS MAKER</h3>
												<p>{productData?.brandStory}</p>

											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											{
												(brandFiles.length) ? <h3 className="aboutHeading">PHOTO GALLERY</h3> : ""
											}

										</div>
									</div>
									<div className="row imgagesShowcase">


										{
											brandFiles.map((_v, _i) => {

												if (_v.filetype == 'BrandPhoto') {
													return (
														<div className="col-md-3">
															<div className="galleryPic">
																<img src={_v?.filepath} className="img-responsive" />
															</div>
														</div>
													)
												}
											})
										}




									</div>

									<div className="row">
										<div className="col-md-12">
											<h3 className="aboutHeading">Videos</h3>


										</div>
									</div>
									<div className="row imgagesShowcase">
										{
											brandFiles.map((_v, _i) => {
												if (_v.filetype == 'BrandVideo') {
													return (
														<div className="col-md-3">
															<div className="galleryPic">
																<video width="100%" controls autoPlay={true} muted playsInline >
																	<source src={_v?.filepath} type="video/webm" />


																</video>

															</div>
														</div>
													)
												}
											})
										}



									</div>




								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}