import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';

import { Modal } from "react-bootstrap";
import Button from '@mui/material/Button';

import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function OrderHistory() {
    document.title = "BevPort - invoice"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("all")
    const [show, setShow] = useState(false);
    const handleClose2 = () => setShow(false);

    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    
    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            sortable: true,
        },
        {
            name: 'Quantity In Case',
            selector: row => row.quantity,
            cell: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.amount),
            cell: row => "$" + parseFloat(row.amount).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDateVal) ? moment(row.orderDateVal, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.orderDateVal ? moment(row.orderDateVal, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => <span style={{ "color": "#00aeef" }}>{(row.status == 1) ? "BOL Created" : (row.status == 2) ? "Order For Pickup" : (row.status == 3) ? "Picked Up" : (row.status == 4) ? "Taken to SSD" : (row.status == 5) ? "Out for Delivery" : (row.status == 7) ? "Delivered" : ""}</span>,
            sortable: true
        },
        {
            name: 'Brewer Pymt Amount',
            selector: row => row.brewerPaymentAmount,
            cell: row => "$" +  row.brewerPaymentAmount,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <>
                <Link className="btn btn-primary" to={"/admin/bol-order-invoice/" + row.orderID} target='_blank' >View</Link></>,
        }

    ];



    const columnsDetails = [


        //{
        //    name: 'ID',
        //    selector: row => row.ID,
        //    cell: row => row.ID,
        //    width: '70px',
        //    sortable: true,
        //    hidden: true
        //},
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '110px',
            sortable: true
        },

        {
            name: 'Status',
            selector: row => row.status,
            cell: row => row.status,
            width: '110px',
            sortable: true
        },

        {
            name: 'Brand',
            selector: row => row.brandName,
            cell: row => row.brandName,
            width: '200px',
            sortable: true
        },

        {
            name: 'Qty. in Cases',
            selector: row => row.qty,
            cell: row => row.qty,
            width: '150px',
            sortable: true
        },

        {
            name: 'Order Date',
            selector: row => (row.orderDate) ? moment(row.orderDate, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.orderDate ? moment(row.orderDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            width: '200px',
            sortable: true,
        },

        {
            name: 'Total Inv Amount',
            selector: row => row.totalInvAmount,
            cell: row => row.totalInvAmount,
            width: '200px',
            sortable: true
        },

         {
            name: 'Brewer Pymt Amount',
            selector: row => row.brewerPaymentAmount,
            cell: row => row.brewerPaymentAmount,
            width: '200px',
            sortable: true
        },

         {
            name: 'Delivery Fee',
            selector: row => row.deliveryFee,
            cell: row => row.deliveryFee,
            width: '150px',
            sortable: true
        },

         {
            name: 'Bevport Net Amount',
            selector: row => row.bevNetAmount,
             cell: row => row.bevNetAmount,
            width: '200px',
            sortable: true
        },

        {
            name: 'Payment Method',
            selector: row => row.paymentMethod,
            cell: row => row.paymentMethod,
            width: '200px',
            sortable: true
        }
       
  
    ];


    const [data, setData] = useState([]);
    const [dataDetails, setDataDetails] = useState([]);


    useEffect(() => {
        getOrderHistory()

    }, [])

 
    const getOrderHistory = async () => {
        await axios.post(API_URL + "GetInvoiceData?code=" + API_KEY,{}, config).then((res) => {

            if (res.data.responseCode == "200") {
                let data = res.data.responseData
               const filterData = data.filter(item => item.status == 7)
                setData(filterData)

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }


    const getOrderDetails = async (paramOrderID, OrderDate) => {

        let formData = {
            "OrderID": paramOrderID
        }
          
        await axios.post(API_URL + "InvoiceDetailsRowClicks?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode == "200") {
              
                let data = res.data.responseData;


                // variables
                var brewerPayAmount = 0.00;
                var paramOrderID = 0;
                var brewerPaymentAmount_array = [];
                var productName_array = [];
                var listingPrice_array = [];
                var bevportNetAmount_array = [];
                var qty_array = [];
                var status_array = [];


                var TotalInvAmount = 0.00;
                var totalQty = 0;

                // loop
                for (var i = 0; i < data.length; i++) {
              
                // 548.40 / 6 = 91.4 = user's listing price at that time
                // 91.4 - 5 = 86.4 = listing price minus deliver fee of $5
                // 86.4 / (1 - .28) = 120

                    var UserListPrice = 0
                    var dFee = 0;
                    var amountIn = 0;

                
                    // totals line make first value in array
                    if (i === 0) {
                        status_array.push("...");
                        productName_array.push("Total");
                        qty_array.push(0);
                        listingPrice_array.push(0);
                        brewerPaymentAmount_array.push(0);
                        bevportNetAmount_array.push(0);
                    }


                    //------------------------------------tax--------------------------------------

                    var tax = 0;
                    var oz = data[i].canSizeValue.replace(/\D/g, '');
                    var caseSize = data[i].caseSizeValue.replace(/\D/g, '');;

                    var cal1 = 0;
                    var cal2 = 0;
                    cal1 = oz * caseSize;
                    cal2 = cal1 * 0.00375;
                    cal2 = cal2.toFixed(2); // round to 2 decimal places

                    tax = cal2;
                    //-------------------------------------------------------------------------------



               
                    // case
                    if (data[i].unitPer === "case") {

                        UserListPrice = data[i].amount / data[i].quantity;
                        dFee = UserListPrice - 5;
                        amountIn = dFee / (1 - .28);
                    }
                    else if (data[i].unitPer === "pack") {

                        UserListPrice = data[i].amount / data[i].quantity;
                        dFee = UserListPrice - 5;
                        amountIn = dFee / (1 - .28);


                        if (data[i].packSize == "Singles") {
                            amountIn = amountIn * 24;
                        }
                        // packsize pack
                       else if (data[i].packSize.replace(/\D/g, '') == 4) {

                            // pack -- srp---  amountin = 91 8 6
                            amountIn = amountIn * 6;
                            // was amount * 2 =  amountIn = amountIn * 2;
                        }
                        else if (data[i].packSize.replace(/\D/g, '') == 6) {
                            amountIn = amountIn * 4;
                        }
                        else if (data[i].packSize.replace(/\D/g, '') == 12) {
                            amountIn = amountIn * 6;
                        }
                        else if (data[i].packSize.replace(/\D/g, '') == 24) {
                            amountIn = amountIn * 1;
                        }
                        //-------------
                    }
                    else if (data[i].unitPer === "unit") {

                        UserListPrice = data[i].amount / data[i].quantity;
                        dFee = UserListPrice - 5;
                        amountIn = dFee / (1 - .28);
                        amountIn = amountIn * 24;

                    }
                    // end tax -------------------------------------------------------------------

                    //alert("tax--->" + tax);

                    var a1 = 28 / 100;
                    var BevportListingPrice_24Unit = amountIn * (1 - a1);
                    var a4 = ((BevportListingPrice_24Unit - 5) - (tax)) - ((BevportListingPrice_24Unit - 5) * 0.1);
                  

                    paramOrderID = data[i].orderID;// order id for all                
                    productName_array.push(data[i].productName)// product name array


                    totalQty = totalQty + data[i].quantity;
                    qty_array.push(data[i].quantity);// qty array
                 

                    TotalInvAmount = TotalInvAmount + (data[i].quantity * UserListPrice);
                    var currentInvAmount = data[i].quantity * UserListPrice;
                    listingPrice_array.push(currentInvAmount);

                    // brewer payment amount
                    brewerPayAmount = brewerPayAmount + a4; // brewer amount top
                    brewerPaymentAmount_array.push(a4.toFixed(2));// push each productsPayamount to array


                    // bevport net amount 
                    var bevNetAmountTemp = ((currentInvAmount - 5.00) - a4);              
                    bevNetAmountTemp = bevNetAmountTemp.toFixed(2);
                    bevportNetAmount_array.push(bevNetAmountTemp);

                    // status array
                    status_array.push(data[i].orderStatus);

                  
                }// end loop---------------



                // set total line values - first value in arrays
                productName_array[0] = data[0].brandName;
                qty_array[0] = totalQty;
                listingPrice_array[0] = TotalInvAmount.toFixed(2);
                brewerPaymentAmount_array[0] = brewerPayAmount.toFixed(2);

                var bevNetAmountTemp2 = (TotalInvAmount - 5.00) - brewerPayAmount;
                bevNetAmountTemp2  = bevNetAmountTemp2.toFixed(2);
                bevportNetAmount_array[0] = bevNetAmountTemp2;


                //----
                invoiceDetailsModalWindow(brewerPaymentAmount_array, productName_array, paramOrderID, listingPrice_array, bevportNetAmount_array, qty_array, status_array, OrderDate, data);
       

            } else {
                //setData([])
                //setOrderList([]);
            }
           // setSubmitLoader(false);
        }).catch(function (error) {
           // setSubmitLoader(false);
        })
    }


    const tableData = {
        columns,
        data
    };


    const tableData2 = {
        columns:columnsDetails,
        data:dataDetails
    };


    const onRowClicked = (data) => {
        setShow(true);// has to be set before loading data into modal window
        getOrderDetails(data.orderID, data.orderDateVal);

    };

    const invoiceDetailsModalWindow = (brewerPaymentAmount_array, productName_array, orderID, listingPrice_array, bevportNetAmount_array, qty_array, status_array, OrderDate, data) => {
      
        var arrayObjects = [];
        for (var i = 0; i < brewerPaymentAmount_array.length; i++) {
         
            // create object to put in modal window grid
            var object = {};
            object.ID = i + 1;
            object.orderID = orderID;


            switch (status_array[i])
            {
                case "1":
                    object.status = "Placed";
                    break;
                case "2":
                    object.status = "Placed";
                    break;
                case "3":
                    object.status = "Ready for Pick Up";
                    break;
                case "4":
                    object.status = "Left Warehouse";
                    break;
                case "5":
                    object.status = "Order Left Warehouse";
                    break;
                case "6":
                    object.status = "Order Staged at SSD";
                    break;
                case "7":
                    object.status = "Order Out for Delivery";
                    break;
                case "8":
                    object.status = "Order Delivered";
                    break;
                case "":
                    object.status = "Declined";
                    break;

                default:
                    object.status = "";
                    break;

            }
            //----------------
            
            object.brandName = productName_array[i];
            object.orderDate = OrderDate; //data[0].createdDate;
            object.qty = qty_array[i];
            object.totalInvAmount =  "$" + listingPrice_array[i];
            object.brewerPaymentAmount =  "$" + brewerPaymentAmount_array[i]; 
            object.deliveryFee = "$" + 5.00;
            object.bevNetAmount = "$" + bevportNetAmount_array[i];

            if (data[0].buyerPaymentType === 1) {
                object.paymentMethod = "Fintech";
            }

            else {
                object.paymentMethod = "Check(COD)";
            }
            //---
       

            arrayObjects.push(object);
        }
        // end loop

        setDataDetails(arrayObjects);
    }


  

    return (
        <>

            <Modal className="invoiceDetailsModal" show={show} onHide={handleClose2} animation={false}>
                <Modal.Header style={{ background: "#587dd7" }} > {/*closeButton*/}
                    <Button style={{ color: "red" }} className="btn-close pull-right" onClick={handleClose2}>
                        X
                    </Button>
                    <Modal.Title className="text-center">Invoice Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div id="invoiceDetailsData"></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DataTableExtensions {...tableData2} export={true} print={false} filterPlaceholder="Search Invoices">
                                <DataTable
                                    columns={columnsDetails}
                                    data={dataDetails}
                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                               
                                />
                            </DataTableExtensions>

                        </div>
                    </div>


                  
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button style={{ background: "#30bf5d3d" }} className="form-control" variant="primary" onClick={handleSubmit_LiquorLicsense}>*/}
                    {/*    Save Changes*/}
                    {/*</Button>*/}

                    <div>
                        &nbsp;
                    </div>

                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            

           
            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
                {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Invoices</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox invoiceTableRow">
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Invoices">
                                    <DataTable
                                            columns={columns}
                                            data={data}
                                            defaultSortAsc={false}
                                            defaultSortFieldId={4}
                                            noDataComponent={<span className='norecordFound'>No record found!</span>}
                                            onRowClicked={onRowClicked}
                                    />
                                    </DataTableExtensions>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}