import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./BrewerSideBar";
import BrewerHeader from "./BrewerHeader";
import BrewerFooter from "./BrewerFooter";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import Select from 'react-select';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BrandList() {
    document.title = "BevPort - Inventory"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const [submitLoader, setSubmitLoader] = useState(true)
    const [activeTab, setActiveTab] = useState("all")
    const [warehouseList, setWarehouseList] = useState([]);
    const [wareHouseName, setWareHouseName] = useState("")

    const collapseSidebar = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        let userID = localStorage.getItem("id")
        getInventory(userID)
        getWareHouseList();
    }, [wareHouseName])
    const finalQuantity = (data) => {


        let finalQty = 0
        let addedData = 0;
        let soldData = 0;
        let damagedData = 0;
        data?.inventoryData.forEach((v, i) => {

            if (v?.inventoryType === "Added") {
                addedData += v?.quantity
            }
            if (v?.inventoryType === "Sold") {
                soldData += v?.quantity
            }
            if (v?.inventoryType === "Damaged") {
                damagedData += v?.quantity
            }
            finalQty = addedData - (soldData + damagedData)
        })
        return finalQty;
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: 'transparent',
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                border: '1px solid #b6b6af',
                boxShadow: null,
            },
            '&:focus': {
                border: '1px solid transparent',
                boxShadow: null,
            }
        })
    };

    const columns = [
        {
            name: 'Brand Name',
            selector: row => row.brandName,
            cell: row => row.brandName,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Product Name',
            selector: row => row.productName,
            sortable: true,
            width: '200px',
        },
        {
            name: 'In Stock',
            selector: row => finalQuantity(row),
            cell: row => finalQuantity(row),
            sortable: true,
            width: '229px',
        },
        {
            name: 'Pending Quantity',
            selector: row => row.pendingQuantity,
            cell: row => row.pendingQuantity,
            sortable: true,
            width: '200px',
        },
        {
            name: '',
            selector: '',
            cell: '',
            sortable: false,
            width: '50px',
        },
        {
            name: '',
            selector: '',
            cell: '',
            sortable: false,
            width: '10px',
        },
        {
            name: 'Action',
            width: '200px',
            selector: row => <>
                <Link className="customPLusIcon" to={"/brewer/inventory-edit/" + row.productID} state={{ type: 'plus', brandID: row.brandID }}><i className="fa fa-plus-square" aria-hidden="true"></i>
                </Link>&nbsp;
                <Link className="customPLusIcon" to={"/brewer/inventory-edit/" + row.productID} state={{ type: 'minus', brandID: row.brandID }}><i className="fa fa-minus-square" aria-hidden="true"></i>
                </Link></>,
        }
    ];

    const [data, setData] = useState([])

    const getInventory = async (userID) => {
        await axios.post(API_URL + "GetInventoryDetailsInfo?code=" + API_KEY, { ID: userID }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let arr = res.data.responseData;
                console.log("dataaa----->", arr);
                if (!wareHouseName) {
                    setData(res.data.responseData)
                } else {
                    const newArray = arr.filter((_v) => {
                        if (_v?.wareHouseName == wareHouseName) {
                            return _v
                        }
                    })
                    setData(newArray);
                }


            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };


    const getWareHouseList = async () => {
        await axios.post(API_URL + "GetWarehouses?code=" + API_KEY, { UserID: localStorage.getItem("id") }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let wareOption = []
                let wareid = [];
                let resData = res.data.responseData
                resData.forEach((da) => {
                    if (da?.status == 1) {
                        wareOption.push({ value: da.wareHouseCode, label: da.wareHouseName, brandID: da.id });
                    }
                }
                );
                setWarehouseList(wareOption);


            } else {

            }
        }).catch(function (error) {

        })
    }


    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>
                {
                    data?.inventoryData?.map((v, i) => {
                        return (
                            <>
                                <div className="expandable-tablerow inventorytbds">
                                    <div className="expandable col one">
                                        {v?.brandName}
                                    </div>
                                    <div className="expandable col two">
                                        {v?.productName}
                                    </div>
                                    <div className="expandable col three">
                                        {(v?.inventoryType != "Pending")?v?.quantity:"-"}
                                    </div>
                                    <div className="expandable col four pdLeft">
                                        {(v?.inventoryType == "Pending")?v?.quantity:"-"}
                                    </div>
                                    <div className="expandable col five">
                                        {v?.inventoryType}
                                    </div>
                                    <div className="expandable col six">
                                        <span className='remarkCols'>
                                            {(v?.remarks) ? v?.remarks : "-"}
                                        </span>
                                    </div>
                                    <div className="expandable col seven">
                                        {moment(v?.createdDate).format('MM-DD-YYYY hh:mm A')}
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </tbody>
        </table>)
    };
    const handleWarehouseName = (e) => {
        setWareHouseName(e.label);
    }

    return (
        <>

            <div className="mainAdminWrapper brewersDashArea">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <BrewerHeader collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">

                        <div className="headingTop">
                            <h1>Inventory</h1>
                        </div>
                        <div className="blank150"></div>

                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className='tbaShowcase'>
                                    <div className="Content-body dataTableSearchBox">



                                        <div className='inventoryTable mange-Fonts'>
                                            <div className="invSelectInventory">
                                                <Select className="selectTodesign"
                                                    styles={customStyles}
                                                    options={warehouseList}
                                                    onChange={handleWarehouseName}
                                                    // value={warehouseValue}
                                                    //  closeMenuOnSelect={true}
                                                    placeholder={<div className="italic-placeholder">Warehouse</div>}
                                                //isSearchable
                                                />
                                            </div>
                                            <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Inventory">
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                                                    defaultSortFieldId={1}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BrewerFooter />
                </div>
            </div>
        </>
    )
}