import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Swal from 'sweetalert2';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};


toast.configure()
export default function AdminContactList() {
	document.title = "BevPort - Contact List"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)
	const collapseSidebar = () => {
		setToggle(!toggle)
	}
	const [data, setData] = useState([])
	useEffect(() => {
		getAdminContactList()
	}, [])
	const getAdminContactList = async (userID) => {
		await axios.post(API_URL + "SalesServiceGetAll?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {


				setData(res.data.responseData)
				console.log("resposnse", res.data.responseData);

			} else {
				setData([])
			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}

	// Delete Contact 
	const deleteContact = async (id) => {
		let formData = {
			"ID": id
		}
		setLoader(true);
		await axios.post(API_URL + "DeleteContactMessage?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				toast.success(res.data.responseMessage)
				getAdminContactList();// rebind the contact list after deleting to show user it deleted
				setLoader(false);
			} else {
				setLoader(false);
				toast.error(res.data.responseMessage)
			}
		}).catch(function (error) {

		})
	}

	// end delete contact function --------------------------




	const ConfirmDelete = (id) => {

		// Check user's local storage --> activityState --> to see what html to show
		var modalHtml = "";
		var Title = "Confirm Delete";


		var modalText = "";

		Swal.fire({
			title: Title,
			text: modalText,
			html: modalHtml,
			/*icon: 'question',*/
			showDenyButton: true,
			allowOutsideClick: false,
			confirmButtonColor: '#205380',
			denyButtonColor: '#205380',
			confirmButtonText: 'Yes',
			denyButtonText: 'NO',
			background: '#fff',
			color: '#000',
			iconColor: "rgb(255, 153, 0)",
			width: "500px",
			padding: '2em',
			reverseButtons: false
		}).then((result) => {


			if (result.isConfirmed) {
				deleteContact(id);
			} else if (result.isDenied) {

			}
		})
	}
	// end LetsGetStarted  -----------------------------------------


	const columns = [


		//{
		//	name: 'ID',
		//	selector: row => (row.id).toLowerCase(),
		//	cell: row => (row.id),
		//	sortable: true,

		//},
		{
			name: 'Name',
			selector: row => (row.userName).toLowerCase(),
			cell: row => (row.userName),
			sortable: true,
		},
		{
			name: 'Charge ID',
			selector: row => (row.chargeID).toLowerCase(),
			cell: row => (row.chargeID),
			sortable: true,
		},
		{
			name: 'Charge Status',
			selector: row => (row.chargeStatus).toLowerCase(),
			cell: row => (row.chargeStatus),
			sortable: true,
		},
		{
			name: 'Reject Reason',
			selector: row => (row.rejectReason).toLowerCase(),
			cell: row => (row.rejectReason),
			sortable: true,
		},
		{
			name: 'Payment Intent ID',
			selector: row => (row.paymentIntentID).toLowerCase(),
			cell: row => (row.paymentIntentID),
			sortable: true,
		},
		{
			name: 'Created Date',
			selector: row => (row.createdDate).toLowerCase(),
			cell: row => row.createdDate ? moment(row.createdDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true,
		},

	
		{
			name: 'Updated Date',
			selector: row => (row.updatedDate).toLowerCase(),
			cell: row => row.updatedDate ? moment(row.updatedDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
			sortable: true,
		},
		{
			name: 'Customer ID',
			selector: row => (row.customerID).toLowerCase(),
			cell: row => (row.customerID),
			sortable: true,
		},
		{
			name: 'Charge Amount',
			selector: row => (row.chargeAmount).toLowerCase(),
			cell: row => (row.chargeAmount),
			sortable: true,
		},
		{
			name: 'Charge Type',
			selector: row => (row.chargeType).toLowerCase(),
			cell: row => (row.chargeType),
			sortable: true,
		},



	];
	const tableData = {
		columns,
		data
	};
	return (
		<>
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Sales Service List</h1>
						</div>
						<div className="blank150"></div>
						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={"No record found!"}


											//defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}
