import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BASE_URL } from './../../config/constant';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import LogoInvoice from './../../assets/images/logo.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";
import moment from 'moment';
import InputMask from 'react-input-mask';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function CreateBol() {
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    let { orderID } = useParams();
    const [data, setData] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [encyptedOrderID, setEncryptedOrderID] = useState("");

    const [addNotes, setAddNotes] = useState("");
    const [notesError, setNotesError] = useState(false);

    const [payableAmount, setPayableAmount] = useState(0);
    const [postData, setPostData] = useState([]);

    const [previousData, setPreviousData] = useState([]);
    const [buyerName, setBuyerName] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [billingAddr, setBillingAddr] = useState("");
    const [deliverAddr, setDeliverAddr] = useState("");

    const [buttonDisable, setButtonDisable] = useState(false);
    let navigate = useNavigate();
    let componentRef = useRef();
    document.title = "#" + orderID
    useEffect(() => {
        getOrderDetail();
    }, [])

    useEffect(() => {
        if (data.length) {
            let formData = []
            let totalPayableAmount = 0;
            data.forEach((val, ind) => {
                totalPayableAmount += parseFloat(val?.amount)
                let f = {
                    productID: val?.productID,
                    Quantity: (val?.quantity == "")?"0":val?.quantity,
                    Amount: val?.amount
                }
                formData.push(f)
            })
            setPayableAmount(parseFloat(totalPayableAmount).toFixed(2))
            setPostData(formData)
        }
    }, [data])

    useEffect(() => {
        if (!data.length) {
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    }, [data])

    const getOrderDetail = async () => {
        await axios.post(API_URL + "GetPickListOrderData?code=" + API_KEY, { OrderID: orderID }, config).then((res) => {
            if (res.data.responseCode == "200") {
                let tempData = res.data.responseData;
                let newArr = [];
                if (tempData.length) {
                    tempData.forEach((_v, _i) => {
                        newArr.push({ "amount": _v?.amount })
                    })
                }
                setPreviousData(newArr);
                setData(res.data.responseData);
                setEncryptedOrderID(res.data.responseData?.[0]?.encryptOrderID);

                setBuyerName(tempData?.[0]?.buyerName);
                setOrderDate(tempData?.[0]?.createdDate);
                let addr = tempData?.[0]?.billingStreet + ", " + tempData?.[0]?.billingCityName + ", " + tempData?.[0]?.billingStateName + ", " + tempData?.[0]?.billingCountryName + ", " + tempData?.[0]?.billingZipCode
                setBillingAddr(addr);
                let dAddr = tempData?.[0]?.buyerAddress + ", " + tempData?.[0]?.cityName + ", " + tempData?.[0]?.stateName + ", " + tempData?.[0]?.zipCode
                setDeliverAddr(dAddr);
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const checkData = () => {
        let dataChange = false;
        if (data.length) {
            data.forEach((_v, _i) => {
                previousData.forEach((_x, _y) => {
                    if (_i == _y) {
                        if (_x?.amount != _v?.amount) {
                            dataChange = true;
                        }
                    }
                })
            })
        }
        return dataChange;
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (checkData()) {
            if (!addNotes) {
                setNotesError(true);
                formIsValid = false;
            }
        } else {
            setNotesError(false);
        }
        if (!data.length) {
            formIsValid = false;
        }
        return formIsValid;
    }
    const handleSubmit = async () => {
        if (handleValidation()) {
            setSubmitLoader(true);
            let formData = {
                OrderID: orderID,
                SSDID: "1",
                FinalPrice: payableAmount,
                Notes: addNotes,
                data: postData
            }
            await axios.post(API_URL + "UpdatePickListStatus?code=" + API_KEY, formData, config).then((res) => {
                if (res.data.responseCode == "200") {
                    navigate("/admin/order-bol");
                    if (checkData()) {
                        sendNotes();
                    }
                    toast.success(res.data.responseMessage)

                }
                setSubmitLoader(false);
            }).catch(function (error) {
                setSubmitLoader(false);
            })
        }
    }

    const sendNotes = async () => {
        let notes = addNotes + " ; " + getStaticNote()
        let formData = {
            "OrderID": orderID,
            "Action": "Created BOL",
            "Notes": notes
        }
        await axios.post(API_URL + "AddOrderLogInfo?code=" + API_KEY, formData, config).then((res) => {
            if (res.data.responseCode == "200") {
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    const getStaticNote = () => {
        let pName = [];
        if (data.length) {
            data.forEach((_v, _i) => {
                previousData.forEach((_x, _y) => {
                    if (_i == _y) {
                        if (_x?.amount != _v?.amount) {
                            pName.push(_v?.productName + "(" + _v?.quantity + ")")
                        }
                    }
                })
            })
        }
        let newString = "";
        newString = "Updated Quantity of " + pName.filter((_v, _i) => {
            return _v;
        })
        return newString;
    }

    const handleQuantity = (e, index) => {
        let count = 0;
        let tempData = [];
        let qty = (e.target.value) ? e.target.value : 0;
        data.forEach((_v, _i) => {
            if (_i === index) {

                _v.finalPrice = _v?.productPrice * parseInt(qty)
                _v.amount = _v?.productPrice * parseInt(qty)
                _v.quantity = e.target.value
            }

            console.log("qty", _v?.productPrice)
            tempData.push(_v)
        })

        setData(tempData)
        console.log(tempData)
    }

    const handleAddNotes = (e) => {
        setAddNotes(e.target.value);
        setNotesError(false);
    }

    const removeProduct = (index) => {
        // if(data.length >1){
        if (index >= 0 && index < data.length) {
            const updatedItems = [...data];
            updatedItems.splice(index, 1); // Remove the item at the specified index
            setData(updatedItems);
            const preData = [...previousData];
            preData.splice(index, 1);
            setPreviousData(preData);
        }
        // }

    }

    var QRValue = BASE_URL + "/view-invoice/" + encyptedOrderID + "/" + localStorage.getItem('id');

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Create BOL</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="invoicePagesWrapper">
                                    <div className="wizardShowOnly">
                                        <div className="wizardShowOnly" ref={(el) => (componentRef = el)}>
                                            <div className="row invoiceHeader">
                                                <div className="col-md-6">
                                                    <div className="LogoInvoice">
                                                        <img src={LogoInvoice} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="InvoiceTextHead">
                                                        <h3>Pack List</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row orderHeader">
                                                <div className="col-md-6">
                                                    <div className="helloAreas">
                                                        <p>Hello {buyerName},</p>
                                                        <p>Thank you for shopping with Bevport. Your order is on the way!</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="orderIdText">
                                                        <p>PACK LIST ID: <span className="orderNumbersId">#{orderID}</span></p>
                                                        <p>{moment(orderDate).format('MM-DD-YYYY hh:mm A')}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row informationBiligns">
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>BILLING ADDRESS</h3>
                                                        <div className="callAdress samllwidth">
                                                            <p>{data?.[0]?.businessName}</p>
                                                            <p>{data?.[0]?.billingStreet}<span className='showblock'>{data?.[0]?.billingCityName}, {data?.[0]?.billingStateName}  {data?.[0]?.billingZipCode}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="billingImforArea">
                                                        <h3>PAYMENT MODE</h3>
                                                        <div className="callAdress">
                                                        <p>{(data?.[0]?.buyerPaymentType == 1)? "Fintech" :"Check(COD)"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="billingImforArea qr-code">
                                                        {/*<QRCode value={QRValue} />*/}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row informationBiligns Shippinhind">
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>DELIVERY ADDRESS</h3>
                                                        <div className="callAdress samllwidth">
                                                            <p>{data?.[0]?.businessName}</p>
                                                            <p>{data?.[0]?.buyerAddress}<span className='showblock'>{data?.[0]?.cityName}, {data?.[0]?.stateName} {data?.[0]?.zipCode}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>DELIVERY METHOD</h3>
                                                        <div className="callAdress">
                                                            <p>Sunshine State Distributing Delivery</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row puchaseHistoryTable">
                                                <div className="col-md-12">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="40%">Item</th>
                                                                <th className="brewerNames" scope="col" >Brand Name</th>
                                                                <th className="qtyTextr" scope="col" width="20%">Cases</th>
                                                                <th className="amountDallrs" scope="col" width="20%">$ Amount</th>
                                                                <th className="amountDallrs" scope="col" width="5%">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((_v, _i) => {
                                                                return [
                                                                    <tr>
                                                                        <td>
                                                                            <span className="mainPrName">{_v?.productName}</span>
                                                                            <span className="brandNameSm">{_v?.breweryName}</span>
                                                                        </td>
                                                                        <td className="brewerNames">{_v?.brandName}</td>
                                                                        <td className="showdata-inputype">
                                                                            <span className="label-text">
                                                                                <InputMask
                                                                                    mask='9999999'
                                                                                    value={_v?.quantity}
                                                                                    onChange={(e) => handleQuantity(e, _i)}
                                                                                    className="form-control"
                                                                                    maskChar={null}
                                                                                ></InputMask>
                                                                            </span>
                                                                        </td>
                                                                        <td className="amountDallrs">{parseFloat(_v?.amount).toFixed(2)}</td>
                                                                        <td className="amountDallrs"><button type="button" className="btn btn-primary" onClick={() => removeProduct(_i)} disabled={(data.length <= 1) ? false : false}><i class="fa fa-minus"></i></button></td>
                                                                    </tr>
                                                                ]
                                                            })}


                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="qtyTextr grandtotals">Grand Total</td>
                                                                <td className="amountDallrs finalPrice"> {(data.length) ? parseFloat(payableAmount).toFixed(2) : 0}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-body">

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="firstname">Add Notes</label>
                                                        <textarea type="text" className={(notesError) ? "form-control error" : "form-control"} placeholder="" onChange={handleAddNotes} value={addNotes} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row Prinatblebtn">
                                            <div className="col-md-12">
                                                <div className="formBtns submit">
                                                    {/* <ReactToPrint
                                                        trigger={() => <button type="button" className="btn btn-secondary save-button"><i className="fa fa-print"></i> Print</button>}
                                                        content={() => componentRef}
                                                    /> */}
                                                    <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={buttonDisable}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default CreateBol